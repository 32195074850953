'use client'

import { useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'

import { InputComponent as Input } from '@/components/input/main'
import { useLoader } from '@/hooks'
import { AuthService } from '@/services'
import { notice } from '@/utils/addon'
import type { FormSignIn } from '@/types/form'

export function FormSignInComponent() {
  // __STATE <Next.14>
  const router = useRouter()
  const loader = useLoader()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormSignIn>({
    defaultValues: {
      username: 'megaversehub@gmail.com',
      keepLoggedIn: true
    }
  })

  // __FUNCTION's
  const onSubmit = handleSubmit(async (formData) => {
    loader.on()

    const response = await AuthService.signIn(formData)
    if (response) {
      notice.success('Admin logged-in ✅', { title: 'Success', duration: 1 })
      router.push('/lobby')
    }

    loader.off()
  })

  // __RENDER
  return (
    <form className='w-80' onSubmit={onSubmit}>
      <div className='grid gap-4'>
        <Input
          key='.username'
          icon='person'
          name='username'
          label='Username'
          register={register}
          errors={errors.username}
          rules={{ required: true }}
        />

        <Input
          key='.password'
          type='password'
          icon='lock'
          name='password'
          label='Password'
          register={register}
          errors={errors.password}
          rules={{ required: true }}
        />
      </div>

      <div className='mt-6 flex flex-col'>
        <button className='btn btn-primary h-12' type='submit'>
          <span className='text-base font-bold capitalize'>sign-in</span>
        </button>
      </div>
    </form>
  )
}
